import React, { createContext, useEffect, useReducer } from "react";
//import jwtDecode from "jwt-decode";
import history from "history.js";

import { authJwtConfig } from "config";

import axios from "axios.js";
import { MatxLoading } from "matx";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  code: null,
  need2FACode: false
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    //axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user, isAuthenticated, need2FACode} = action.payload;
      return {
        ...state,
        user,
        isAuthenticated,
        need2FACode,
      };
    }
    case "SELECT": {
      const { user } = action.payload;

      return {
        ...state,
        user,
      };
    }
    case "SENDCODE": {
      const { code } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        code,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  sendCode: () => Promise.resolve(),
  tfaSelect: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleRemoteException = (err) => {
      if (err.class === 'SSOAuthenticationFailedHTTPException') {
        document.location = authJwtConfig.ssoUrl;
      }
  }

  const login = async (username, password) => {
    let response = null;
    try {
      response = await axios.post("/api/auth/login", {username: username, password: password});
    } catch(e) {
      handleRemoteException(e);
      throw e;
    }
    const { user } = response.data;

    if (response.data.connected.error === '2FA code required! Select how you want to receive the code') {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          isAuthenticated: false,
          need2FACode: true,
        },
      });
    } else {
      setSession(1)
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          isAuthenticated: true,
          need2FACode: false
        },
      });
    }
  };

  const tfaSelect = async (value) => {
    let response = null
    try {
      response = await axios.post("/api/auth/tfa_select", {tfa_method: value});
    } catch (e) {
      handleRemoteException(e);
      throw e;
    }
    const { user } = response.data;

    dispatch({
      type: "SELECT",
      payload: {
        user,
      },
    });
  };

  const sendCode = async (code) => {
    let response = null
    try {
      response = await axios.post("/api/auth/tfa_send_code", {tfa_code: code});
    } catch (e) {
      handleRemoteException(e);
      throw e;
    }

    const { user } = response.data;
    setSession(1)
    dispatch({
      type: "SENDCODE",
      payload: {
        user,
        code,
        isAuthenticated: true,
      },
    });
  };


  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });
    
    const { accessToken, user } = response.data;

    setSession(accessToken);
    
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    await axios.post("/api/auth/logout");
    setSession(null);

    dispatch({ type: "LOGOUT" });

    document.location = authJwtConfig.ssoUrl;
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/api/auth/profile");
        const user = response.data;

        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });

        handleRemoteException(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.need2FACode) {
      history.push("/session/two-factor/");
    }
  }, [state.need2FACode]);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        sendCode,
        tfaSelect,
        isAuthenticated: state.isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
